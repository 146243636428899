
  import { debounce } from 'lodash'
  import common from '@mixins/common'
  import filters from '@filters/filters'
  import { formatPx2Rem } from '@utils/index'
  import { dateFilter, floatSub, isIOS, prohibitNegative } from '@utils/utils'
  import { statusMap } from '@/dictionaries/checkOut'
  import {
    getDetailAPI,
    submitCheckOutCheckAPI,
    submitCheckOutCheckOffAPI,
    getHouseIotAbilityApi,
    getWaterElectricityApi
  } from '@api/checkOut'

  export default {
    name: 'CheckOutDetail',
    mixins: [common, filters],
    components: {},
    data() {
      return {
        id: '',
        type: '',
        detail: {},
        headerTitle: '退房信息',
        statusMap,
        isLoading: false, // 是否在加载数据
        isRefreshPage: false, // 是否需要刷新页面
        noDataMessage: '', // 列表为空时的提示文案
        checkOutPayDate: '', // 退款时间
        waterPaidAmount: 0, // 已收水费
        waterTotalAmount: undefined, // 应收水费
        powerPaidAmount: 0, // 已收电费
        powerTotalAmount: undefined, // 应收电费
        // waterElectricity: 0, // 水电的金额
        remark: undefined, // 备注
        minDate: new Date(),
        maxDate: new Date(2099, 12, 31),
        date: '',
        show: false,
        radio: 0,
        useBack: false
      }
    },
    computed: {
      yingtuihuan() {
        if (this.detail.customerType == 1) {
          return (this.detail.inDeposit * 1 + this.waterElectricity * 1).toFixed(2)
        } else if (this.detail.customerType == 2) {
          return (this.detail.afterRentBreakAmount * 1 + this.detail.inDeposit * 1 + this.waterElectricity * 1).toFixed(2)
        } else if (this.detail.customerType == 3) {
          return (this.detail.afterRentBreakAmount * 1 + this.detail.inDeposit * 1 + this.waterElectricity * 1 + this.detail.paidBreakAmount * 1 + this.detail.paidExceedRentAmount * 1).toFixed(2)
        } else {
          return 0
        }
      },
      yingjiaona() {
        console.log(this.detail, 'detail')
        let amount = this.getYingShouAmount()
        return Number(amount) + Number(this.detail.delayAmountUnpaid)
      },
      shiji() {
        return (this.yingtuihuan * 1 - this.yingjiaona * 1).toFixed(2)
      },
      zhinaday() {
        return this.detail.overdueDays * 1
      },
      zhinajin() {
        // return (this.detail.monthRentAmount / 30 * this.zhinaday).toFixed(2)
        return this.detail.rentBreakAmount * 1
      },
      // 水费结余
      waterReturnAmount() {
        return (this.waterPaidAmount * 100000 - this.waterTotalAmount * 100000) / 100000 || 0
      },
      // 电费结余
      powerReturnAmount() {
        return (this.powerPaidAmount * 100000 - this.powerTotalAmount * 100000) / 100000 || 0
      },
      // 水电结余费
      waterElectricity: {
        get() {
          return (this.waterReturnAmount + this.powerReturnAmount).toFixed(2)
        },
        set(val) {
          return val
        }
      }
    },
    methods: {
      prohibitNegative,
      onConfirm(date) {
        this.show = false
        this.date = this.dateFilter(date, '-', 'YYYY-MM-DD')
      },
      formatPx2Rem,
      // 日期格式化
      dateFilter,
      async handleCallback({ isError, errorMessage = undefined }) { // 回调处理
        if (isError) {
          this.$toast(errorMessage || '获取失败!')
          /* if (errorMessage && errorMessage.includes('timeout')) {
            // 判断是否是网络请求超时
            this.isRefreshPage = true
            this.noDataMessage = '网络请求超时'
          } else if (errorMessage && errorMessage.includes('502')) {
            // 判断是否是服务器错误
            this.isRefreshPage = true
            this.noDataMessage = '服务器错误，502错误'
          } */
        }
      },
      getYingShouAmount() {
        if (this.detail.customerType == 1 && this.detail.chargeAmount !== 0) {
          return (this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.chargeAmount * 1).toFixed(2)
        } else if (this.detail.customerType == 2 && this.detail.chargeAmount !== 0) {
          return (this.detail.breakAmount * 1 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.chargeAmount * 1).toFixed(2)
        } else if (this.detail.customerType == 3 && this.detail.chargeAmount !== 0) {
          return (this.detail.breakAmount * 2 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.chargeAmount * 1).toFixed(2)
        } else if (this.detail.customerType == 1) {
          return (this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1).toFixed(2)
        } else if (this.detail.customerType == 2) {
          return (this.detail.breakAmount * 1 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1).toFixed(2)
        } else if (this.detail.customerType == 3) {
          return (this.detail.breakAmount * 1 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.rentBreakAmount * 1).toFixed(2)
        } else {
          return 0
        }
      },
      handleSubmit: debounce(function() {
        //        if (!this.date) {
        //          this.$toast('请选择退款时间')
        //          return
        //        }

        if (this.waterPaidAmount == undefined) {
          this.$toast('请填写已收水费')
          return
        }

        if (!this.waterTotalAmount) {
          this.$toast('请填写应收水费')
          return
        }

        if (this.powerPaidAmount == undefined) {
          this.$toast('请填写已收电费')
          return
        }

        if (!this.powerTotalAmount) {
          this.$toast('请填写应收电费')
          return
        }

        // if (!this.waterElectricity) {
        //   this.$toast('请填写水电结余费')
        //   return
        // }
        this.$dialog.confirm({
          title: '提示',
          message: '是否确认？',
          confirmButtonColor: '#D8B084'
        }).then(async () => {
          // on confirm
          await this.submitCheckOutCheckData()
        }).catch(() => {
          // on cancel
        })
      }, 200),
      async submitCheckOutCheckData({ isShowLoading } = { isShowLoading: true }) { // 完成接待
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let {
            id,
            waterElectricity
            //            date
          } = this
          let response = null
          const {
            waterTotalAmount,
            waterPaidAmount,
            waterReturnAmount,
            powerTotalAmount,
            powerPaidAmount,
            powerReturnAmount,
            remark
          } = this
          if (this.radio === 0) {
            response = await submitCheckOutCheckAPI({
              id,
              waterElectricity,
              waterTotalAmount,
              waterPaidAmount,
              waterReturnAmount,
              powerTotalAmount,
              powerPaidAmount,
              powerReturnAmount,
              remark
              //            checkOutPayDate: date
            })
          } else {
            response = await submitCheckOutCheckOffAPI({
              id,
              waterElectricity,
              waterTotalAmount,
              waterPaidAmount,
              waterReturnAmount,
              powerTotalAmount,
              powerPaidAmount,
              powerReturnAmount,
              remark
              //            checkOutPayDate: date
            })
          }
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            // 接口请求处理成功之后回跳
            this.$toast({
              message: '提交成功！',
              onClose: () => {
                // this.$emit('checkOutDetailUpdate') // 更新上一级页面的数据
                // setTimeout(() => {
                //   this.$router.go(-1)
                // }, 500)

                if (this.useBack) {
                  if (isIOS() === 'ios') {
                    window.webkit.messageHandlers.backPage.postMessage(null)
                  } else {
                    if (window.commonJS) {
                      window.commonJS.backPage('')
                    }
                  }
                } else {
                  this.$router.replace({
                    path: '/checkOut'
                  })
                }
              }
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async initData() {
      },
      getHouseIotAbility(houseId) {
        getHouseIotAbilityApi({ houseId }).then(res => {
          if (res.data.code === '10000' && res.data.data.ifIot === 1) {
            // 获取水电信息
            this.getWaterElectricity()
          }
        })
      },
      getWaterElectricity() {
        getWaterElectricityApi({ dcContractId: this.detail.dcContractId }).then(res => {
          if (res.data.code === '10000') {
            let result = res.data.data
            this.waterPaidAmount = result.waterPaidAmount
            this.waterTotalAmount = result.waterTotalAmount
            // this.waterReturnAmount = 1
            this.powerPaidAmount = result.powerPaidAmount
            this.powerTotalAmount = result.powerTotalAmount
            // this.powerReturnAmount = 1
            // this.waterElectricity = 1
          }
        })
      }
    },
    filters: {},
    watch: {},
    created() {
    },
    async mounted() {
      const { token, projectId, projectName, userId, userName, useBack, id } = this.$route.query
      if (useBack) {
        this.useBack = true
      }
      if (token && projectId) {
        this.$store.commit('user/SET_TOKEN', token)
        this.$store.commit('user/SET_USER_INFO', { dcProjectId: projectId, userId, userName, projectName })
        this.$store.commit('user/SET_PROJECT_INFO', { dcProjectId: projectId, projectName })
      }
      this.id = id
      let res = await getDetailAPI({ id })
      this.detail = res.data.data || {}
      let {
        rentPaidAmount,
        rentAmount,
        rentBreakAmount
      } = res.data.data
      if (this.detail.customerType == 2) {
        let amount = floatSub(rentPaidAmount, rentAmount)
        this.detail.afterRentBreakAmount = floatSub(amount, rentBreakAmount)
      } else {
        this.detail.afterRentBreakAmount = 0
      }
      if (res.data.data.status == 4) {
        this.type = 'check'
        if (res.data.data.genWaterTotalAmount) {
          this.waterTotalAmount = res.data.data.genWaterTotalAmount
        }
        if (res.data.data.genPowerTotalAmount) {
          this.powerTotalAmount = res.data.data.genPowerTotalAmount
        }
        this.getHouseIotAbility(res.data.data.houseId)
      } else if (res.data.data.status == 5 || res.data.data.status == 6 || res.data.data.status == 7) {
        this.type = 'detail'
      }

      if (this.detail.status > 4) {
        this.waterTotalAmount = this.detail.waterTotalAmount
        this.waterPaidAmount = this.detail.waterPaidAmount
        this.powerTotalAmount = this.detail.powerTotalAmount
        this.powerPaidAmount = this.detail.powerPaidAmount
        this.waterElectricity = this.detail.waterElectricity
        this.date = this.detail.checkOutPayDate
      }

      console.log(this.detail, 'detail')
      this.initData()
      this.waterPaidAmount = this.detail.waterPaidAmount || 0
      this.powerPaidAmount = this.detail.powerPaidAmount || 0
      this.radio = this.detail.isOnLine || 0
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
