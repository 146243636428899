var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          attrs: {
            "title": "租客姓名",
            "value": _vm.detail.customerName
          }
        }), _c('van-cell', {
          attrs: {
            "title": "房号",
            "value": _vm.detail.roomNo
          }
        }), _c('van-cell', {
          attrs: {
            "title": "开始时间",
            "value": _vm.detail.contractStartTime
          }
        }), _c('van-cell', {
          attrs: {
            "title": "结束时间",
            "value": _vm.detail.contractEndTime
          }
        }), _c('van-cell', {
          attrs: {
            "title": "退房类型"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_vm.detail.customerType == 1 ? _c('span', {
                staticStyle: {
                  "color": "#21D788"
                }
              }, [_vm._v("正常退房")]) : _vm._e(), _vm.detail.customerType == 2 ? _c('span', {
                staticStyle: {
                  "color": "#FDA93E"
                }
              }, [_vm._v("提前退房")]) : _vm._e(), _vm.detail.customerType == 3 ? _c('span', {
                staticStyle: {
                  "color": "#FDA93E"
                }
              }, [_vm._v("逾期退房")]) : _vm._e()];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          attrs: {
            "title": "离店日期",
            "value": _vm.detail.leaveTime
          }
        }), _c('van-cell', {
          attrs: {
            "title": "退房原因",
            "title-style": "flex:0.5;",
            "value": _vm.detail.personalReason
          }
        }), _c('van-cell', {
          attrs: {
            "title": "退款时间",
            "title-style": "flex:0.5;",
            "value": "7个工作日内"
          }
        })], 1), _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#999999"
                }
              }, [_vm._v("水费标准：" + _vm._s(_vm.detail.waterPrice) + "元/吨")])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#999999"
                }
              }, [_vm._v("电费标准：" + _vm._s(_vm.detail.wattPrice) + "元/度")])];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          attrs: {
            "title": "签约时水表度数",
            "value": (_vm.detail.checkInWaterDegree || '') + '吨'
          }
        }), _c('van-cell', {
          attrs: {
            "title": "签约时电表度数",
            "value": (_vm.detail.checkInElectricityDegree || '') + '度'
          }
        }), _c('van-cell', {
          attrs: {
            "title": "当前水表抄表度数",
            "value": (_vm.detail.waterMaterDegree || '') + '吨'
          }
        }), _c('van-cell', {
          attrs: {
            "title": "当前电表抄表度数",
            "value": (_vm.detail.wattMaterDegree || '') + '度'
          }
        })], 1), _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          attrs: {
            "border": false
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "font-size": "14px"
                }
              }, [_vm._v("水表图片")])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "font-size": "14px",
                  "display": "block",
                  "width": "100%",
                  "text-align": "left",
                  "color": "#323233"
                }
              }, [_vm._v("电表图片")])];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "font-size": "14px",
                  "display": "block"
                }
              }, [_c('van-image', {
                attrs: {
                  "width": "140",
                  "height": "77",
                  "lazy-load": "",
                  "src": _vm.detail.wattMaterDegreeImg
                }
              })], 1)];
            },
            proxy: true
          }, {
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "font-size": "14px",
                  "display": "block",
                  "width": "100%",
                  "text-align": "left",
                  "color": "#323233"
                }
              }, [_c('van-image', {
                attrs: {
                  "width": "140",
                  "height": "77",
                  "lazy-load": "",
                  "src": _vm.detail.waterMaterDegreeImg
                }
              })], 1)];
            },
            proxy: true
          }])
        })], 1), _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          attrs: {
            "border": false
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "font-size": "15px",
                  "font-weight": "400"
                }
              }, [_vm._v("退房费明细")])];
            },
            proxy: true
          }])
        }), _vm.detail.customerType == 2 || _vm.detail.customerType == 3 ? _c('van-cell', {
          attrs: {
            "border": false
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm.detail.customerType == 2 ? _c('span', {
                staticStyle: {
                  "font-size": "15px",
                  "font-weight": "400"
                }
              }, [_vm._v("剩余月份租金明细")]) : _vm._e(), _vm.detail.customerType == 3 ? _c('span', {
                staticStyle: {
                  "font-size": "15px",
                  "font-weight": "400"
                }
              }, [_vm._v("退房逾期租金明细")]) : _vm._e()];
            },
            proxy: true
          }, {
            key: "label",
            fn: function () {
              return [_vm.detail.customerType == 2 ? _c('span', [_vm._v(_vm._s((_vm.detail.afterCheckOutRentStartDate ? _vm.detail.afterCheckOutRentStartDate : '') + (_vm.detail.afterCheckOutRentStartDate || _vm.detail.afterCheckOutRentEndDate ? '~' : '') + (_vm.detail.afterCheckOutRentEndDate ? _vm.detail.afterCheckOutRentEndDate : '')))]) : _vm._e(), _vm.detail.customerType == 3 ? _c('span', [_vm._v(_vm._s((_vm.detail.overdueStartDate ? _vm.detail.overdueStartDate : '') + (_vm.detail.overdueStartDate || _vm.detail.leaveTime ? '~' : '') + (_vm.detail.leaveTime ? _vm.detail.leaveTime : '')))]) : _vm._e()];
            },
            proxy: true
          }], null, false, 2086447387)
        }) : _vm._e(), _vm.detail.customerType == 3 ? _c('van-cell', {
          attrs: {
            "border": false
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v("月总综合服务费")])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v(_vm._s(_vm.detail.monthRentAmount) + "元")])];
            },
            proxy: true
          }], null, false, 3464773051)
        }) : _vm._e(), _vm.detail.customerType == 3 ? _c('van-cell', {
          attrs: {
            "border": false
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v("日均综合服务费")])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v(_vm._s(_vm.detail.dailyRentAmount) + "元")])];
            },
            proxy: true
          }], null, false, 2048661891)
        }) : _vm._e(), _c('van-cell', {
          attrs: {
            "border": false
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v("应收滞纳金")])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v(_vm._s(_vm.detail.delayAmountAll) + "元")])];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          attrs: {
            "border": false
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v("已收滞纳金")])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v(_vm._s(_vm.detail.delayAmount) + "元")])];
            },
            proxy: true
          }])
        }), _vm.detail.customerType == 3 ? _c('van-cell', {
          attrs: {
            "title": "逾期租金"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.rentBreakAmount) + "元")])];
            },
            proxy: true
          }], null, false, 1227362484)
        }) : _vm._e(), _vm.detail.customerType == 2 || _vm.detail.customerType == 3 ? _c('van-cell', {
          attrs: {
            "title": "违约金"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.breakAmount) + "元")])];
            },
            proxy: true
          }], null, false, 1648129881)
        }) : _vm._e(), _vm.detail.customerType == 3 ? _c('van-cell', {
          attrs: {
            "title": "已付违约金"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#dbae74"
                }
              }, [_vm._v(_vm._s(_vm.detail.paidBreakAmount) + "元")])];
            },
            proxy: true
          }], null, false, 903688260)
        }) : _vm._e(), _vm.detail.customerType == 3 ? _c('van-cell', {
          attrs: {
            "title": "已付逾期租金"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#dbae74"
                }
              }, [_vm._v(_vm._s(_vm.detail.paidExceedRentAmount) + "元")])];
            },
            proxy: true
          }], null, false, 724518060)
        }) : _vm._e(), _c('van-cell', {
          attrs: {
            "title": "已收综合服务费"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v(_vm._s(_vm.detail.rentPaidAmount) + "元")])];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          attrs: {
            "title": "应收综合服务费"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v(_vm._s(_vm.detail.rentAmount) + "元")])];
            },
            proxy: true
          }])
        }), _vm.detail.customerType == 2 ? _c('van-cell', {
          attrs: {
            "title": "违约租金"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v(_vm._s(_vm.detail.rentBreakAmount) + "元")])];
            },
            proxy: true
          }], null, false, 990853204)
        }) : _vm._e(), _vm.detail.customerType == 2 ? _c('van-cell', {
          attrs: {
            "title": "应退综合服务费"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.detail.afterRentBreakAmount) + "元")])];
            },
            proxy: true
          }], null, false, 338713041)
        }) : _vm._e(), _c('van-cell', {
          attrs: {
            "title": "退还保证金"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.detail.inDeposit) + "元")])];
            },
            proxy: true
          }])
        }), _vm.type === 'detail' ? _c('van-cell', {
          attrs: {
            "title": "已收水费"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.waterPaidAmount) + "元")])];
            },
            proxy: true
          }], null, false, 1146303153)
        }) : _vm._e(), _vm.type === 'check' && (_vm.detail.customerType == 1 || _vm.detail.customerType == 2 || _vm.detail.customerType == 3) ? _c('van-field', {
          attrs: {
            "type": "number",
            "placeholder": "请输入已收水费",
            "disabled": true,
            "input-align": "right"
          },
          on: {
            "input": e => _vm.waterPaidAmount = _vm.prohibitNegative(e, 2, 10)
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("已收水费")])];
            },
            proxy: true
          }, {
            key: "extra",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74",
                  "padding": "0 10px"
                }
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 3661784608),
          model: {
            value: _vm.waterPaidAmount,
            callback: function ($$v) {
              _vm.waterPaidAmount = $$v;
            },
            expression: "waterPaidAmount"
          }
        }) : _vm._e(), _vm.type === 'detail' ? _c('van-cell', {
          attrs: {
            "title": "应收水费"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.waterTotalAmount) + "元")])];
            },
            proxy: true
          }], null, false, 3843724975)
        }) : _vm._e(), _vm.type === 'check' && (_vm.detail.customerType == 1 || _vm.detail.customerType == 2 || _vm.detail.customerType == 3) ? _c('van-field', {
          attrs: {
            "placeholder": "请输入应收水费",
            "disabled": _vm.detail.status > 4,
            "input-align": "right"
          },
          on: {
            "input": e => _vm.waterTotalAmount = _vm.prohibitNegative(e, 2, 10)
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("应收水费")])];
            },
            proxy: true
          }, {
            key: "extra",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74",
                  "padding": "0 10px"
                }
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 2013804294),
          model: {
            value: _vm.waterTotalAmount,
            callback: function ($$v) {
              _vm.waterTotalAmount = $$v;
            },
            expression: "waterTotalAmount"
          }
        }) : _vm._e(), _c('van-cell', {
          attrs: {
            "title": "水费结余"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.waterReturnAmount) + "元")])];
            },
            proxy: true
          }])
        }), _vm.type === 'detail' ? _c('van-cell', {
          attrs: {
            "title": "已收电费"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.powerPaidAmount) + "元")])];
            },
            proxy: true
          }], null, false, 4284937531)
        }) : _vm._e(), _vm.type === 'check' && (_vm.detail.customerType == 1 || _vm.detail.customerType == 2 || _vm.detail.customerType == 3) ? _c('van-field', {
          attrs: {
            "type": "number",
            "placeholder": "请输入已收电费",
            "disabled": true,
            "input-align": "right"
          },
          on: {
            "input": e => _vm.powerPaidAmount = _vm.prohibitNegative(e, 2, 10)
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("已收电费")])];
            },
            proxy: true
          }, {
            key: "extra",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74",
                  "padding": "0 10px"
                }
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 3000366305),
          model: {
            value: _vm.powerPaidAmount,
            callback: function ($$v) {
              _vm.powerPaidAmount = $$v;
            },
            expression: "powerPaidAmount"
          }
        }) : _vm._e(), _vm.type === 'detail' ? _c('van-cell', {
          attrs: {
            "title": "应收电费"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.powerTotalAmount) + "元")])];
            },
            proxy: true
          }], null, false, 3583064997)
        }) : _vm._e(), _vm.type === 'check' && (_vm.detail.customerType == 1 || _vm.detail.customerType == 2 || _vm.detail.customerType == 3) ? _c('van-field', {
          attrs: {
            "placeholder": "请输入应收电费",
            "disabled": _vm.detail.status > 4,
            "input-align": "right"
          },
          on: {
            "input": e => _vm.powerTotalAmount = _vm.prohibitNegative(e, 2, 10)
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("应收电费")])];
            },
            proxy: true
          }, {
            key: "extra",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74",
                  "padding": "0 10px"
                }
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 2335221191),
          model: {
            value: _vm.powerTotalAmount,
            callback: function ($$v) {
              _vm.powerTotalAmount = $$v;
            },
            expression: "powerTotalAmount"
          }
        }) : _vm._e(), _c('van-cell', {
          attrs: {
            "title": "电费结余"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.powerReturnAmount) + "元")])];
            },
            proxy: true
          }])
        }), _vm.type === 'detail' ? _c('van-cell', {
          attrs: {
            "title": "水电结余费"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.detail.waterElectricity) + "元")])];
            },
            proxy: true
          }], null, false, 460625561)
        }) : _vm._e(), _vm.type === 'check' && (_vm.detail.customerType == 1 || _vm.detail.customerType == 2 || _vm.detail.customerType == 3) ? _c('van-field', {
          attrs: {
            "disabled": true,
            "input-align": "right"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("水电结余费")])];
            },
            proxy: true
          }, {
            key: "extra",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74",
                  "padding": "0 10px"
                }
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 1696709979),
          model: {
            value: _vm.waterElectricity,
            callback: function ($$v) {
              _vm.waterElectricity = $$v;
            },
            expression: "waterElectricity"
          }
        }) : _vm._e(), _vm.detail.chargeAmount !== 0 ? _c('van-cell', {
          attrs: {
            "title": "挂账总金额"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.chargeAmount) + "元")])];
            },
            proxy: true
          }], null, false, 984442524)
        }) : _vm._e(), _c('van-cell', {
          attrs: {
            "title": "赔偿费"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.damages) + "元")])];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          attrs: {
            "title": "打扫费"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.cleanFee) + "元")])];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          attrs: {
            "title": "额外增值费"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.otherFee) + "元")])];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          attrs: {
            "title": "应退还总费用",
            "title-style": "flex:2.5;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.yingtuihuan) + "元")])];
            },
            proxy: true
          }, {
            key: "label",
            fn: function () {
              return [_vm.detail.customerType == 1 ? _c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v("(" + _vm._s(_vm.detail.inDeposit) + "+" + _vm._s(~~_vm.waterElectricity) + "=" + _vm._s(_vm.yingtuihuan) + "元)")]) : _vm._e(), _vm.detail.customerType == 2 ? _c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v("(" + _vm._s(_vm.detail.afterRentBreakAmount) + "+" + _vm._s(_vm.detail.inDeposit) + "+" + _vm._s(~~_vm.waterElectricity) + "=" + _vm._s(_vm.yingtuihuan) + "元)")]) : _vm._e(), _vm.detail.customerType == 3 ? _c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v("(" + _vm._s(_vm.detail.inDeposit) + "+" + _vm._s(~~_vm.waterElectricity) + "+" + _vm._s(_vm.detail.paidBreakAmount) + "+" + _vm._s(_vm.detail.paidExceedRentAmount) + "=" + _vm._s(_vm.yingtuihuan) + "元)")]) : _vm._e()];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          attrs: {
            "title": "应缴纳总费用",
            "title-style": "flex:2.5;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.yingjiaona) + "元")])];
            },
            proxy: true
          }, {
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v("(" + _vm._s(_vm.detail.delayAmountUnpaid) + "+")]), _vm.detail.customerType == 1 && _vm.detail.chargeAmount !== 0 ? _c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.damages) + "+" + _vm._s(_vm.detail.cleanFee) + "+" + _vm._s(_vm.detail.otherFee) + "+" + _vm._s(_vm.detail.chargeAmount) + "=" + _vm._s(_vm.yingjiaona) + "元)")]) : _vm.detail.customerType == 2 && _vm.detail.chargeAmount !== 0 ? _c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.breakAmount) + "+" + _vm._s(_vm.detail.damages) + "+" + _vm._s(_vm.detail.cleanFee) + "+" + _vm._s(_vm.detail.otherFee) + "+" + _vm._s(_vm.detail.chargeAmount) + "=" + _vm._s(_vm.yingjiaona) + "元)")]) : _vm.detail.customerType == 3 && _vm.detail.chargeAmount !== 0 ? _c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.breakAmount) + "+" + _vm._s(_vm.detail.breakAmount) + "+" + _vm._s(_vm.detail.damages) + "+" + _vm._s(_vm.detail.cleanFee) + "+" + _vm._s(_vm.detail.otherFee) + "+" + _vm._s(_vm.detail.chargeAmount) + "=" + _vm._s(_vm.yingjiaona) + "元)")]) : _vm.detail.customerType == 1 ? _c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.damages) + "+" + _vm._s(_vm.detail.cleanFee) + "+" + _vm._s(_vm.detail.otherFee) + "=" + _vm._s(_vm.yingjiaona) + "元)")]) : _vm.detail.customerType == 2 ? _c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.breakAmount) + "+" + _vm._s(_vm.detail.damages) + "+" + _vm._s(_vm.detail.cleanFee) + "+" + _vm._s(_vm.detail.otherFee) + "=" + _vm._s(_vm.yingjiaona) + "元)")]) : _vm.detail.customerType == 3 ? _c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.breakAmount) + "+" + _vm._s(_vm.detail.rentBreakAmount) + "+" + _vm._s(_vm.detail.damages) + "+" + _vm._s(_vm.detail.cleanFee) + "+" + _vm._s(_vm.detail.otherFee) + "=" + _vm._s(_vm.yingjiaona) + "元)")]) : _vm._e()];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          attrs: {
            "title": "实际总退房费用",
            "title-style": "flex:2.5;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v(_vm._s(_vm.shiji) + "元")])];
            },
            proxy: true
          }, {
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("(" + _vm._s(_vm.yingtuihuan) + "-" + _vm._s(_vm.yingjiaona) + "=" + _vm._s(_vm.shiji) + "元)")])];
            },
            proxy: true
          }])
        }), _vm.type === 'detail' ? _c('van-field', {
          attrs: {
            "value": _vm.detail.remark,
            "autosize": "",
            "label": "备注(选填)",
            "type": "textarea",
            "readonly": ""
          }
        }) : _vm._e(), _vm.type === 'check' && (_vm.detail.customerType == 1 || _vm.detail.customerType == 2 || _vm.detail.customerType == 3) ? _c('van-field', {
          attrs: {
            "autosize": "",
            "label": "备注",
            "type": "textarea",
            "placeholder": "请输入备注"
          },
          model: {
            value: _vm.remark,
            callback: function ($$v) {
              _vm.remark = $$v;
            },
            expression: "remark"
          }
        }) : _vm._e()], 1), _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          attrs: {
            "border": false
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "font-size": "15px",
                  "font-weight": "400"
                }
              }, [_vm._v("请选择租户退房协议签署方式")])];
            },
            proxy: true
          }])
        }), _c('van-radio-group', {
          model: {
            value: _vm.radio,
            callback: function ($$v) {
              _vm.radio = $$v;
            },
            expression: "radio"
          }
        }, [_vm.type === 'check' && `${_vm.detail.status}` === '4' ? _c('van-cell-group', [_c('van-cell', {
          attrs: {
            "clickable": ""
          },
          on: {
            "click": function ($event) {
              _vm.radio = 0;
            }
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('van-radio', {
                attrs: {
                  "name": 0
                }
              }, [_vm._v("租户线上签署")])];
            },
            proxy: true
          }], null, false, 2272446181)
        }), _c('van-cell', {
          attrs: {
            "clickable": ""
          },
          on: {
            "click": function ($event) {
              _vm.radio = 1;
            }
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('van-radio', {
                attrs: {
                  "name": 1
                }
              }, [_vm._v("租户线下签署")])];
            },
            proxy: true
          }], null, false, 1513883621)
        })], 1) : _c('van-cell-group', [_vm.detail.isOnLine === 0 ? _c('van-cell', {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('van-radio', {
                attrs: {
                  "name": 0
                }
              }, [_vm._v("租户线上签署")])];
            },
            proxy: true
          }], null, false, 2272446181)
        }) : _c('van-cell', {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('van-radio', {
                attrs: {
                  "name": 1
                }
              }, [_vm._v("租户线下签署")])];
            },
            proxy: true
          }])
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_vm.type === 'check' && `${_vm.detail.status}` === '4' ? _c('div', {
          staticClass: "cyui-actionbar"
        }, [_c('div', {
          staticClass: "cyui-actionbar-item cyui-actionbar-btns"
        }, [_c('van-button', {
          staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
          attrs: {
            "round": "",
            "block": "",
            "type": "info"
          },
          on: {
            "click": _vm.handleSubmit
          }
        }, [_vm._v(" 确认 ")])], 1)]) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };